import { Toast } from 'antd-mobile'
// import './index.css';

/**
 * custom_toast_error: error
 * custom_toast_warn: warn
 * custom_toast_normal: normal
 * */
const ToastTip = ({ className, text, duration = 2000 }) => {
  const element = document.getElementsByClassName(className)
  if (!element || element.length < 1) {
    const create_div = document.createElement('div')
    create_div.setAttribute('class', className)
    document.body.appendChild(create_div)
  }

  return Toast.show({
    getContainer: document.getElementsByClassName(className)[0],
    content: text,
    duration: duration,
  })

  // 使用方法
  // ToastTip({className: 'custom_toast_error', text: '我是错误'})
}
export default ToastTip
