import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchRoutes } from 'react-router-config'
import classNames from 'classnames'
import { Layout } from 'antd'
import { initTheme } from '_src/themes'
import { useAppSelector } from '_src/store/hooks'
import routes from '_src/routes'
import { GlobalWallet } from '_src/hooks/useWallet'
import { flattenArray, findArrayParents } from '../../utils'
import './index.less'
import PassFooter from '../../components/PassFooter'
import { useAppDispatch } from '../../store/hooks'
import { setActiveUrl } from '_src/store/menu/reducer'
import RandomDivs from '../../components/RandomBox'
import { isTopLevelPage } from '../../utils/appBridge'
const { Content } = Layout

export default function PageLayout({ children, className }) {
  const { theme } = useAppSelector((store) => store.global)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const routeArray = flattenArray(routes())
  const [show, setShow] = useState(false)
  const [isRandomBox, setIsRandomBox] = useState(0)
  // 导航操作 start
  const handleSetActiveUrl = (pathname) => {
    // 设置导航焦点
    const marchRoutes = matchRoutes(routeArray, pathname)
    const path = marchRoutes[0]?.match?.path || ''

    if (path) {
      const urls = findArrayParents(routes(), (data) => data.path === path)
      urls && dispatch(setActiveUrl(urls))
    }
  }
  const historyParameter = (pathname) => {
    const historyItem = routeArray.find((item) => item.path.split(/[/#]/)[1] === pathname.split(/[/#]/)[1])
    if (historyItem) {
      const { hideFooter, isRandomBox } = historyItem
      setShow(hideFooter)
      setIsRandomBox(isRandomBox)
      isTopLevelPage(hideFooter)
    }
    console.log(historyItem, 'historyItem')
  }
  useEffect(() => {
    historyParameter(location.pathname)
    history.listen((historyLocation) => {
      historyParameter(location.pathname)

      // 每次路由变化都会执行这个方法
      // const historyItem = routeArray.find((item) => item.path === historyLocation.pathname)
      // if (historyItem) {
      //   const { hideFooter = false, isRandomBox } = historyItem
      //   setShow(hideFooter)
      //   setIsRandomBox(isRandomBox)
      // }

      handleSetActiveUrl(historyLocation.pathname)

      window && window.scrollTo(0, 0)
    })
  }, [history])
  useEffect(() => {
    //  设置导航焦点
    handleSetActiveUrl(pathname)
    // 钱包
    initTheme(theme || 'dark')
  }, [])
  return (
    <GlobalWallet.Provider value={''}>
      <Layout className={classNames('pass-page-layout', className)}>
        {!!isRandomBox && <RandomDivs numDivs={isRandomBox} />}
        {show && <PassFooter />}
        <Content className={classNames('cust-content')}>{children}</Content>
      </Layout>
    </GlobalWallet.Provider>
  )
}
