export function imgUrl(imgName) {
  return require(`../assets/images/${imgName}`)
}

export function qiniuUrl(url, px) {
  if (!url) {
    return url
  }
  if (!px) {
    px = 300
  }
  if (url.indexOf("?") != -1) {
    // return url + "&" + "imageView2/2/w/" + px
    return url 
  } else {
    return url + "?" + "imageView2/2/w/" + px
  }
}
