import React from 'react'
// import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import './index.less'

import PassMenu from '../Menu'
const PassFooter = ({ className }) => {
  return (
    <div id={classNames('pass_header', className)}>
      <PassMenu />
    </div>
  )
}

export default PassFooter
