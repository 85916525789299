import React, { useEffect, useState } from 'react'

import './index.less'

function RandomDivs({ numDivs }) {
  const [divs, setDivs] = useState([])

  useEffect(() => {
    const newDivs = []
    for (let i = 0; i < numDivs; i++) {
      const sizeW = Math.floor(Math.random() * 100) + 50 //生成50到150之间的随机数作为div大小
      const sizeH = Math.floor(Math.random() * 50) + 50 //生成50到100之间的随机数作为div大小
      const top = Math.floor(Math.random() * 400) + 200 //生成随机的top位置
      const left = Math.floor(Math.random() * 200) + 1 //生成随机的left位置
      const newDiv = (
        <div
          className="randomBox"
          key={i}
          style={{
            width: `${sizeW}px`,
            height: `${sizeH}px`,
            top: `${top}px`,
            left: `${left}px`,
            position: 'absolute',
          }}
        ></div>
      )
      newDivs.push(newDiv)
    }

    setDivs(newDivs)
  }, [])

  return <div>{divs}</div>
}
export default RandomDivs
