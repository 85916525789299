import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { load, save } from 'redux-localstorage-simple'

import global from './global/reducer'
import menu from './menu/reducer'

const store = configureStore({
  reducer: {
    global,
    menu,
    // detail,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }).concat(save({ states: ['global'] })) // TODO: debounce: 1000 优化函数，但是导致数据延迟，暂时去掉
    // }).concat(save({ states: ["global"], debounce: 1000 }));
  },
  preloadedState: load({
    states: ['global'],
    disableWarnings: process.env.NODE_ENV === 'production',
  }),
})

// store.dispatch(upateOnLine(true));

setupListeners(store.dispatch)

export default store
