import { imgUrl } from '../../utils/staticUrl'

export const marketUrl = '/'
export const shenQiTab = (t) => {
  const defaultNavs = [
    {
      label: t('首页'),
      url: '/newIndex',
      key: '/newIndex',
      icon: imgUrl('newIndex/home.png'),
      icon_active: imgUrl('newIndex/active_home.png'),
    },
    {
      label: t('福利'),
      url: '',
      key: '',
      icon: imgUrl('newIndex/welfare.png'),
      icon_active: imgUrl('newIndex/active_welfare.png'),
    },
    {
      label: t('土地'),
      url: '',
      key: '',
      icon: imgUrl('newIndex/land.png'),
      icon_active: imgUrl('newIndex/active_land.png'),
    },
    {
      label: t('我的'),
      url: '/mine',
      key: '/mine',
      icon: imgUrl('newIndex/mine.png'),
      icon_active: imgUrl('newIndex/active_mine.png'),
    },
  ]
  return defaultNavs
}
export const navs = (t) => {
  const defaultNavs = [
    {
      label: t('首页'),
      url: '/index',
      key: '/index',
      icon: imgUrl('aacCommon/tabicon_home.png'),
      icon_active: imgUrl('aacCommon/tabicon_home_on.png'),
    },
    // {
    //   label: t('header.Home'),
    //   url: '/home',
    //   key: '/home',
    //   icon: imgUrl('aacCommon/tabicon_home.png'),
    //   icon_active: imgUrl('aacCommon/tabicon_home_on.png'),
    // },
    // {
    //   label: t('header.Discover'),
    //   url: '/discover',
    //   key: '/discover',
    //   icon: imgUrl('aacCommon/tabicon_discover.png'),
    //   icon_active: imgUrl('aacCommon/tabicon_discover_on.png'),
    // },
    // {
    //   label: t('活动中心'),
    //   url: '/activity',
    //   key: '/activity',
    //   icon: imgUrl('aacCommon/tabicon_discover.png'),
    //   icon_active: imgUrl('aacCommon/tabicon_discover_on.png'),
    // },
    // 25号上线的活动
    {
      label: t('活动中心'),
      url: '/offlineActivities',
      key: '/offlineActivities',
      icon: imgUrl('aacCommon/tabicon_discover.png'),
      icon_active: imgUrl('aacCommon/tabicon_discover_on.png'),
    },

    // {
    //   label: t('header.Trade'),
    //   url: '/trade',
    //   key: '/trade',
    //   icon: imgUrl('aacCommon/tabicon_deal.png'),
    //   icon_active: imgUrl('aacCommon/tabicon_deal_on.png'),
    // },
    {
      label: t('header.Mine'),
      url: '/mine',
      key: '/mine',
      icon: imgUrl('aacCommon/tabicon_mine.png'),
      icon_active: imgUrl('aacCommon/tabicon_mine_on.png'),
    },
  ]

  return defaultNavs
}
