import React from 'react'
import { notification } from 'antd'

import Icon from '_src/components/Icon'

export function isDev() {
  return process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST'
}

/**
 * @description: 通知信息
 * @param {String} title
 * @param {String} icon  icon-status-error  icon-status-success  icon-status-warning
 * @param {Number} duration  秒
 * @return {Null}
 */
export const createNotification = (title, icon = 'icon-status-success', duration = 3) => {
  const message = (
    <div className="cust-notification-content">
      <Icon type={icon} className="notification-icon" />
      <div className="notification-title">{title}</div>
    </div>
  )

  notification.open({
    className: 'cust-notification',
    closeIcon: <Icon type="icon-notification-close" style={{ fontSize: 18, opacity: 0.8 }} />,
    duration,
    message,
    description: '',
    top: 140,
  })
}

/**
 * @description: 找到节点的所有父节点
 * @param {Array} array
 * @param {String} url
 * @return {Array}
 */
export const findArrayParents = (array, func, path = []) => {
  if (!array) return []
  for (const data of array) {
    path.push(data.path)
    if (func(data)) return path
    if (data.children) {
      const findChildren = findArrayParents(data.children, func, path)
      if (findChildren.length) return findChildren
    }
    path.pop()
  }
  return []
}

/**
 * @description: 扁平化数组
 * @param {Array} array
 * @return {Array}
 */
export const flattenArray = (array) => {
  return array.reduce((result, item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'children') && Array.isArray(item.children)) {
      return result.concat([item]).concat(flattenArray(item.children))
    }
    return result.concat([item])
  }, [])
}

/**
 * @description: 数字不使用科学计数法
 * @param {String} num   原字符串
 * @return {String}
 */
export function toNonExponential(num) {
  const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
}
