import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  theme: 'dark',
  lng: '',
  isLogin: false,
  userInfo: {},
  isFold: false,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lng = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setIsFold: (state, action) => {
      state.isFold = action.payload
    },
    clearGlobalStore: (state) => {
      state.isLogin = false
      state.userInfo = {}
    },
  },
})

export const { setLanguage, setTheme, setIsLogin, setIsFold, setUserInfo, clearGlobalStore } = globalSlice.actions

export default globalSlice.reducer
