import i18next from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next } from 'react-i18next'

// import store from "_src/store";

import translation_en_US from './en_US.json'
import translation_zh_CN from './zh_CN.json'
import translation_es_ES from './es_ES.json'
const resources = {
  en_US: {
    translation: translation_en_US,
  },
  zh_CN: {
    translation: translation_zh_CN,
  },
  es_ES: {
    translation: translation_es_ES,
  },
}

i18next
  .use(Backend) //嗅探当前浏览器语言
  // .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next) //init i18next
  .init({
    debug: false,
    resources,
    // lng: "en_US",
    fallbackLng: 'zh_CN', // 如果用户选择的语言不被支持，那么使用英文
    whitelist: ['en_US', 'zh_CN', 'es_ES'], // 支持的语言 英文和繁体
    // backend: {
    //   // 自动获取该文件夹下的json文件
    //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },
    interpolation: {
      escapeValue: false,
    },
    react: {
      // wait: true,
      useSuspense: false,
    },
    // nonExplicitSupportedLngs: true,
    detection: {
      caches: ['localStorage'],
    },
  })

// i18next.on("initialized", function (options) {
//   console.log("initialized", options);
// });
// i18next.on("loaded", (loaded) => {
//   console.log("loaded", loaded);
// });

// i18next.on("languageChanged", (language) => {
//   console.log("languageChanged", language);
// });

// i18next.on("missingKey", (lngs, namespace, key, res) => {
//   console.log(key, res);
// });

export default i18next
